import React from 'react';
import css from 'src/theme/css';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import ModuleWrapper from 'src/components/ModuleWrapper';
import Text from 'src/components/Text';
import { Grid, Flex, Box } from 'src/components/FlexBox';
import Layers from 'src/components/Layers';
import SmartButton from 'src/components/SmartButton';
import { useBreakpoint } from 'src/components/Breakpoints';
import Logo from 'src/assets/svg/c2c-logo-white.inline.svg';

export default () => {
  const isLarge = useBreakpoint(0);
  const { gradient, gradientSmall, image } = useStaticQuery(graphql`
    query {
      gradient: contentfulAsset(title: { eq: "c2c-gradient" }) {
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      gradientSmall: contentfulAsset(title: { eq: "c2c-gradient-small" }) {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      image: contentfulAsset(title: { eq: "home-c2c-cta" }) {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  `);
  return (
    <ModuleWrapper css={css({ px: 'pageMargin', py: 4 })}>
      <Layers css={css({ borderRadius: 2, overflow: 'hidden' })}>
        <Img
          {...(isLarge ? gradient : gradientSmall)}
          css={css({ borderRadius: 2 })}
          style={{
            position: 'absolute',
            transform: isLarge ? 'scaleX(-1)' : 'scaleX(1)',
          }}
        />
        <Grid
          gridTemplateColumns={['auto', null, '1fr 1fr']}
          gx={[5, null, null, 6]}
          gy={5}
          css={css({
            px: 'pageMargin',
            py: 5,
            position: 'relative',
          })}
        >
          <Box
            flexDirection="column"
            justifyContent="center"
            alignItems={['center', null, 'flex-start']}
            textAlign={['center', null, 'start']}
          >
            <Logo />
            <Text variant="heading.xxl" css={css({ maxWidth: '40rem', mt: 3 })}>
              New Camera to Cloud updates
            </Text>
            <Text
              variant="aktiv"
              color="offWhite"
              css={css({ maxWidth: '25rem', mt: 4 })}
            >
              Introducing new software integrations for your production teams,
              easier device authentication, and more.
            </Text>
            <Flex
              gy={4}
              gx={4}
              css={css({ mt: 4 })}
              flexDirection={['column', null, null, null, 'row']}
              alignItems={['center', null, 'start', null, 'center']}
            >
              <SmartButton
                label="Learn more"
                action="Link"
                variant="Knockout"
                url="/c2c"
              />
              <SmartButton
                label="Watch the video"
                action="Video Modal"
                variant="Play"
                videoUrl="https://frame.wistia.com/medias/fuceu33gdd"
              />
            </Flex>
          </Box>
          <Img {...image} css={css({ borderRadius: 2, overflow: 'hidden' })} />
        </Grid>
      </Layers>
    </ModuleWrapper>
  );
};
