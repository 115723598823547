import styled, { css } from 'styled-components';
import { media, gradientText } from 'src/utils/mixins';
import globalThemeStyles from 'src/utils/styles/globalThemeStyles';

export const Layout = styled.div`
  ${(props) => globalThemeStyles[props.moduleTheme]};

  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  overflow: hidden;
  ${media.medium`
    grid-template-columns: 50% 50%;
  `};
`;

export const Text = styled.div`
  align-self: center;
  align-items: center;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: ${({ theme }) => theme.layout.sidePadding.xsm};
  padding-right: ${({ theme }) => theme.layout.sidePadding.xsm};

  ${media.small`
    padding-left: ${({ theme }) => theme.layout.sidePadding.sm};
    padding-right: ${({ theme }) => theme.layout.sidePadding.sm};
  `}

  ${media.medium`
    align-items: flex-start;
    text-align: left;
    padding-left: ${({ theme }) => theme.layout.sidePadding.md};
    padding-right: 0;
  `}
`;

export const Title = styled.h2(({ theme }) => theme.text.h2);

export const GradientText = styled.span(gradientText);

export const Paragraph = styled.p(
  ({ theme }) => css`
    ${theme.text.p}
    max-width: 30em;
  `
);

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;

  ${media.medium`
    width: 65%;
  `};
`;

export const DeviceWrapper = styled.div`
  pointer-events: none;
  position: relative;
  height: 100vw;

  ${media.medium`
    min-height: 50vw;
    height: 100vh;
  `}
`;
