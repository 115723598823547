import React from 'react';
import css from 'src/theme/css';
import BackgroundImage from 'gatsby-background-image';
import switchcase from 'src/utils/switchcase';
import RichText from 'src/components/RichText';
import PhoneAnimation from './PhoneAnimation';
import TabletAnimation from './TabletAnimation';
import WebAnimation from './WebAnimation';
import { useBreakpoint } from 'src/components/Breakpoints';

import * as S from './styles';

const smallBackgroundImageStyles = {
  position: 'absolute',
  backgroundAttachment: 'fixed',
};

const largeBackgroundImageStyles = {
  position: 'absolute',
  backgroundAttachment: 'fixed',
  backgroundSize: 'contain',
  backgroundPosition: 'right',
};

const DeviceAnimationModule = ({ other, text, media }) => {
  const isTwoUp = useBreakpoint(1);

  const AnimationComponent = switchcase(
    [
      ['phone', () => PhoneAnimation],
      ['tablet', () => TabletAnimation],
      ['browser', () => WebAnimation],
    ],
    JSON.parse(other.internal.content).device,
    () => TabletAnimation
  );

  return (
    <S.Layout moduleTheme="White">
      <RichText
        {...text}
        css={css({
          py: 'sectionMargin',
          pl: 'pageMargin',
          pr: ['pageMargin', null, 0],
          justifyContent: 'center',
        })}
      />
      <S.DeviceWrapper>
        <S.BackgroundImage
          as={BackgroundImage}
          style={
            isTwoUp ? largeBackgroundImageStyles : smallBackgroundImageStyles
          }
          {...(media && media[0])}
        />
        {AnimationComponent && <AnimationComponent />}
      </S.DeviceWrapper>
    </S.Layout>
  );
};

export default DeviceAnimationModule;
