import React, { useRef, useState } from 'react';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import IconPreset from 'src/components/IconPreset';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { Flex, Box } from 'src/components/FlexBox';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import { useStore } from 'src/components/GlobalState';
import { clamp, progress, mix } from '@popmotion/popcorn';
import RichText from 'src/components/RichText';

const ProgressBar = ({
  scrollProgress,
  width,
  index,
  upload,
  minutes,
  icon,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const bar = useRef();

  useAnimationFrame(() => {
    const p = scrollProgress.current - index * 0.05;

    setIsComplete(p > width);

    if (bar.current) {
      const x = mix(-100, 0, clamp(0, 1, Math.min(p, width)));
      bar.current.style.transform = `translateX(${x}%)`;
    }
  });

  return (
    <Flex gx={[3, 4]}>
      <IconPreset
        identifier={icon}
        flexShrink={0}
        css={css({
          display: 'flex',
          size: 4,
          transform: 'translateY(calc(0.25rem - 50%))',
          '> svg': {
            width: '100%',
          },
        })}
      />
      <Flex
        flexGrow={1}
        flexDirection="column"
        gy={2}
        css={css({ position: 'relative' })}
      >
        <Box
          css={css({
            width: '100%',
            borderRadius: 1,
            overflow: 'hidden',
            transform: 'translate3d(0, 0, 0)',
          })}
        >
          <Box
            ref={bar}
            css={css({
              position: 'relative',
              width: '100%',
              borderRadius: 1,
              overflow: 'hidden',
              transition: 'background-color 500ms',
              bg: isComplete
                ? index === 0
                  ? 'white'
                  : 'whiteAlpha.5'
                : 'whiteAlpha.1',
            })}
          >
            <Box
              css={css({
                width: '100%',
                height: 2,
                bg: ({ colors }) =>
                  `linear-gradient(to right, ${colors.whiteAlpha[0]} 50%, ${colors.white})`,
                opacity: isComplete ? 0 : 1,
                transition: 'opacity 500ms',
              })}
            />
          </Box>
        </Box>
        {index === 0 && (
          <>
            <Box
              css={css({
                position: 'absolute',
                left: `${width * 100}%`,
                top: 0,
                width: 'calc(0.5rem + 8px)',
                height: 'calc(0.5rem + 8px)',
                border: 'solid 2px',
                borderColor: 'whiteAlpha.8',
                borderRadius: '100vw',
                opacity: isComplete ? 1 : 0,
                transition: 'opacity 100ms',
                transform: 'translate(calc(-0.25rem - 50%), -50%)',
              })}
            />
            <Box
              css={css({
                position: 'absolute',
                left: `${width * 100}%`,
                top: 0,
                width: 'calc(0.5rem + 16px)',
                height: 'calc(0.5rem + 16px)',
                border: 'solid 2px',
                borderColor: 'whiteAlpha.4',
                borderRadius: '100vw',
                opacity: isComplete ? 1 : 0,
                transition: 'opacity 100ms',
                transform: 'translate(calc(-0.25rem - 50%), -50%)',
              })}
            />
          </>
        )}
        <Text
          variant="small"
          css={css({
            transition: 'opacity 200ms ease-out, transform 200ms ease-out',
            opacity: isComplete ? 1 : 0,
            transform: isComplete ? 'translateY(0%)' : 'translateY(-50%)',
          })}
        >{`${upload} in ${minutes} minutes`}</Text>
      </Flex>
    </Flex>
  );
};

export default ({ text, references, other, ...rest }) => {
  const { items, upload, innerTitle, innerSubtitle } = JSON.parse(
    other.internal.content
  );
  const parsedMinutes = items.map(({ minutes }) => Number(minutes));
  const maxMinutes = Math.max(...parsedMinutes);
  const widths = parsedMinutes.map((minutes) => minutes / maxMinutes);
  const scrollProgress = useRef(0);
  const [ref, bounds] = useBounds();
  const reflow = useStore((state) => state.reflow);

  useAnimationFrame(() => {
    if (!bounds.current) return;
    scrollProgress.current = progress(
      reflow.height - bounds.current.height * 0.5,
      reflow.height * 0.1,
      bounds.current.y
    );
  });

  return (
    <ModuleWrapper
      {...rest}
      ref={ref}
      background={references && references[0]}
      css={css({
        my: 'sectionMargin',
        mx: 'pageMargin',
      })}
    >
      {text && (
        <RichText
          {...text}
          size="m"
          alignItems="center"
          textAlign="center"
          css={css({ mb: 5 })}
        />
      )}
      <Box
        css={css({
          flexDirection: 'column',
          px: 'pageMargin',
          py: 'pageMargin',
          borderRadius: 2,
          bg: 'sunset',
        })}
      >
        {innerTitle && innerSubtitle && (
          <Text
            fontFamily="aktiv"
            fontSize={[3, 4]}
            lineHeight="heading"
            letterSpacing="-0.025em"
            css={css({
              mb: 5,
              maxWidth: '70rem',
              '> b': { fontWeight: 'medium' },
            })}
          >
            <b>{innerTitle}</b> {innerSubtitle}
          </Text>
        )}
        <Flex flexDirection="column" gy={4}>
          {items.map((item, i) => (
            <ProgressBar
              key={item.minutes}
              scrollProgress={scrollProgress}
              width={widths[i]}
              index={i}
              upload={upload}
              {...item}
            />
          ))}
        </Flex>
      </Box>
    </ModuleWrapper>
  );
};
