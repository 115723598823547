import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { clamp, mix, progress } from '@popmotion/popcorn';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import { useStore } from 'src/components/GlobalState';

const Wrapper = styled.div`
  perspective: 4000px;
  width: 100%;
  height: 100%;
`;

const BaseWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: translate(50%, 50%);
`;

const Base = styled.div`
  position: absolute;
  transform-style: preserve-3d;
`;

const Comment1 = styled.div`
  position: absolute;
  top: 1099px;
  left: 81px;
`;

const Comment2 = styled.div`
  position: absolute;
  top: 1321px;
  left: 81px;
`;

const PhoneAnimation = () => {
  const { base, comment1, comment2 } = useStaticQuery(graphql`
    query {
      base: contentfulAsset(
        title: { eq: "device-animation-module-phone-base" }
      ) {
        fixed(width: 889, height: 1779) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      comment1: contentfulAsset(
        title: { eq: "device-animation-module-phone-comment-1" }
      ) {
        fixed(width: 730, height: 228) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      comment2: contentfulAsset(
        title: { eq: "device-animation-module-phone-comment-2" }
      ) {
        fixed(width: 730, height: 200) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
    }
  `);

  // cache viewport
  const reflow = useStore((state) => state.reflow);

  // cache wrapper node and bounds
  const [nodeWrapper, boundsWrapper] = useBounds();

  // cache child nodes
  const nodeBase = useRef();
  const nodeComment1 = useRef();
  const nodeComment2 = useRef();

  // set image width
  const imageWidth = 889;

  // in frame
  useAnimationFrame(() => {
    // determine vertical progress of Base
    const progressBase = clamp(
      0,
      1,
      progress(-reflow.height, reflow.height, boundsWrapper.current.y)
    );

    // determine vertical progress of Comments
    const progressComment = clamp(
      0,
      1,
      progress(0, reflow.height, boundsWrapper.current.y)
    );

    // style Base
    nodeBase.current.style.transform = `
      translateX(-50%)
      translateY(-50%)
      scale(${(boundsWrapper.current.width / imageWidth) * 0.35})
      rotateX(${mix(1, 40, progressBase)}deg)
      rotateY(${mix(1, -20, progressBase)}deg)
      rotateZ(20deg)
      translateZ(0px)
    `;

    // style Comments
    // translateZ(0px) breaks z-indexing, so stop at 1
    const zComment1 = mix(1, 400, progressComment);
    const zComment2 = mix(1, 800, progressComment);

    nodeComment1.current.style.transform = `translateZ(${zComment1}px)`;
    nodeComment2.current.style.transform = `translateZ(${zComment2}px)`;
  });

  return (
    <Wrapper ref={nodeWrapper}>
      <BaseWrapper>
        <Base ref={nodeBase}>
          <Img {...base} />
          <Comment1 ref={nodeComment1}>
            <Img {...comment1} />
          </Comment1>
          <Comment2 ref={nodeComment2}>
            <Img {...comment2} />
          </Comment2>
        </Base>
      </BaseWrapper>
    </Wrapper>
  );
};

export default PhoneAnimation;
