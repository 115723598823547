import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { minWidth } from 'src/utils/breakpoints';
import { clamp, interpolate, mix, progress } from '@popmotion/popcorn';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import { useStore } from 'src/components/GlobalState';

const Wrapper = styled.div`
  perspective: 4000px;
  width: 100%;
  height: 100%;

  @media ${minWidth.m} {
    transform: translateX(10%);
  }
`;

const BaseWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: translate(50%, 50%);
`;

const Base = styled.div`
  position: absolute;
  transform-style: preserve-3d;
`;

const Card = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const cardsTop = 400;
const cardsLeft = 435;
const cardsWidth = 400;
const cardsHeight = 352;
const cardsZSeparation = 0.2;
const columnCount = 4;

function arrayIndexToGridIndex(i, c) {
  return [i % c, Math.floor(i / c)];
}

const WebAnimation = () => {
  const { base, cards } = useStaticQuery(graphql`
    query {
      base: contentfulAsset(title: { eq: "device-animation-module-web-base" }) {
        fixed(width: 2052, height: 1382) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      cards: allContentfulAsset(
        filter: { title: { glob: "device-animation-module-web-card-*" } }
      ) {
        edges {
          node {
            id
            fixed(width: 372, height: 316) {
              ...GatsbyContentfulFixed_noBase64
            }
          }
        }
      }
    }
  `);

  // cache viewport
  const reflow = useStore((state) => state.reflow);

  // cache wrapper node and bounds
  const [nodeWrapper, boundsWrapper] = useBounds();

  // cache child nodes
  const nodeBase = useRef();

  // set image width
  const imageWidth = 2052;

  // in frame
  useAnimationFrame(() => {
    // determine vertical progress
    const p = clamp(
      0,
      1,
      progress(-reflow.height, reflow.height, boundsWrapper.current.y)
    );

    // style Base
    nodeBase.current.style.transform = `
      translateX(-50%)
      translateY(-50%)
      scale(${(boundsWrapper.current.width / imageWidth) * 0.9})
      rotateX(0deg)
      rotateY(${mix(0, -30, p)}deg)
      rotateZ(0deg)
      translateZ(0px)
    `;

    // style Cards
    // skip 1st child (the Img component)
    Array.from(nodeBase.current.children)
      .slice(1)
      .forEach((node, index) => {
        const [i, j] = arrayIndexToGridIndex(index, columnCount);
        const start = (i + j - 2) * -cardsZSeparation;

        node.style.transform = `
          translateX(${cardsLeft + i * cardsWidth}px)
          translateY(${cardsTop + j * cardsHeight}px)
          translateZ(${interpolate([start, start + 0.5], [600, 10])(1 - p)}px)
        `;
      });
  });

  const renderCards = cards.edges.map(({ node }, index) => {
    return (
      <Card key={node.id}>
        <Img {...node} />
      </Card>
    );
  });

  return (
    <Wrapper ref={nodeWrapper}>
      <BaseWrapper>
        <Base ref={nodeBase}>
          <Img {...base} />
          {renderCards}
        </Base>
      </BaseWrapper>
    </Wrapper>
  );
};

export default WebAnimation;
