import React from 'react';
import css from 'src/theme/css';
import ModuleWrapper from 'src/components/ModuleWrapper';
import Img from 'gatsby-image'; ///withIEPolyfill;
import { useStaticQuery, graphql } from 'gatsby';
import RichText from 'src/components/RichText';
import { Grid, Flex, Box } from 'src/components/FlexBox';
import Link from 'src/components/Link';
import Text from 'src/components/Text';
// import AtomosLogo from 'src/assets/svg/logos/atomos.inline.svg';
// import TeradekLogo from 'src/assets/svg/logos/teradek.inline.svg';
// import SoundDevicesLogo from 'src/assets/svg/logos/sound-devices.inline.svg';
// import VivianaLogo from 'src/assets/svg/logos/viviana.inline.svg';
// import FilmdataboxLogo from 'src/assets/svg/logos/fdx.inline.svg';
// import AatonLogo from 'src/assets/svg/logos/aaton.inline.svg';

const Cell = ({ url, text, logo }) => {
  const hasNoLink = !url;
  return (
    <Link
      url={url}
      isDisabled={hasNoLink}
      css={css({
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        cursor: hasNoLink ? 'default' : '',
        borderRadius: 2,
        background: ({ colors }) =>
          `linear-gradient(to bottom, ${colors.black}, ${colors.gray800})`,
        [hasNoLink ? '' : '&:hover > *']: {
          opacity: 1,
        },
        '&::after': {
          content: `''`,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '2px',
          bg: 'c2cGradient',
          transition: 'opacity 200ms ease',
          opacity: 1,
        },
      })}
    >
      <Box
        css={css({
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: 0,
          background: ({ colors }) =>
            `linear-gradient(to bottom, ${colors.black}, ${colors.gray700})`,
          borderRadius: 2,
        })}
      />
      <Flex
        flexDirection="column"
        gy={4}
        flexGrow={1}
        css={css({ position: 'relative', p: 5 })}
      >
        <Box
          css={css({
            height: '30px',
            transform: 'translate3d(0, 0, 0)',
          })}
        >
          <Img
            fluid={logo.childImageSharp.fluid}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center left',
            }}
            css={css({ width: '100%', height: '30px' })}
          />
        </Box>
        <Text>{text}</Text>
      </Flex>
    </Link>
  );
};

export default ({ text }) => {
  const {
    teradekLogo,
    sounddevicesLogo,
    aatonLogo,
    atomosLogo,
    fdxLogo,
    vivianaLogo,
  } = useStaticQuery(graphql`
    query {
      teradekLogo: file(relativePath: { eq: "svg/logos/teradek.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sounddevicesLogo: file(
        relativePath: { eq: "svg/logos/sound-devices.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      aatonLogo: file(relativePath: { eq: "svg/logos/aaton.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      fdxLogo: file(relativePath: { eq: "svg/logos/fdx.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      vivianaLogo: file(relativePath: { eq: "svg/logos/viviana.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      atomosLogo: file(relativePath: { eq: "svg/logos/atomos.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <ModuleWrapper colorMode="dark">
      <Flex
        flexDirection="column"
        gy={5}
        css={css({
          px: 'pageMargin',
          pt: 4,
          pb: 'sectionMargin',
          width: '100%',
          mx: 'auto',
          maxWidth: '80rem',
        })}
      >
        {text && <RichText {...text} size="m" alignItems="center" />}
        <Grid
          alignSelf="stretch"
          alignItems="start"
          justifyItems="center"
          justifyContent="center"
          gridTemplateColumns={[1, 2].map((n) => `repeat(${n}, 1fr)`)}
          gridGap={3}
        >
          <Cell
            url="/c2c"
            logo={teradekLogo}
            text="Deliver 4K HDR proxy files directly into Frame.io with the new Teradek Serv 4K or HD proxies using the Teradek Cube 655."
          />
          <Cell
            url="/c2c"
            logo={atomosLogo}
            text="Record and upload H.265 proxies from a wide range of popular DSLRs or mirrorless HDMI and SDI cameras."
          />
          <Cell
            url="/c2c"
            logo={aatonLogo}
            text="Record and upload uncompressed WAV audio directly into Frame.io."
          />
          <Cell
            url="/c2c"
            logo={vivianaLogo}
            text="Upload audio files from Sound Devices MixPre and Zoom multi-track field recorders using Viviana Cloud. "
          />
          <Cell
            url="/c2c"
            logo={sounddevicesLogo}
            text="Capture and transmit original audio files directly into Frame.io."
          />
          <Cell
            url="/c2c"
            logo={fdxLogo}
            text="The all-in-one solution for backing up, transcoding, and uploading media from hard drives and SD cards."
          />
        </Grid>
      </Flex>
    </ModuleWrapper>
  );
};
