import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import css from 'src/theme/css';
import RichText from 'src/components/RichText';
import ModuleWrapper from 'src/components/ModuleWrapper';
import TextBlock from 'src/components/TextBlock';
import { Grid, Box } from 'src/components/FlexBox';
import { useResponsiveValue } from 'src/components/Breakpoints';

export default ({ text, media, references, ...other }) => {
  const hasFullImage = useResponsiveValue([
    false,
    null,
    typeof media[1] !== 'undefined',
    false,
  ]);
  const textBlock =
    references &&
    references.find(({ __typename }) => __typename === 'ContentfulTextBlock');
  const background =
    references &&
    references.find(({ __typename }) => __typename === 'ContentfulImageGroup');
  return (
    <ModuleWrapper
      {...other}
      background={background}
      css={css({ mx: 'pageMargin', my: 'sectionMargin', overflow: 'visible' })}
    >
      {textBlock && (
        <TextBlock {...textBlock} css={css({ mb: 'sectionMargin' })} />
      )}
      <Box
        css={css({
          position: 'relative',
        })}
      >
        <Box
          css={css({
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            bg: 'sunset',
            borderRadius: 2,
          })}
          style={{ position: 'absolute' }}
        />
        <Grid
          gridTemplateRows={['auto auto', null, 'auto']}
          gridTemplateColumns={['1fr', null, '22rem 1fr', '1fr 2fr', '1fr 1fr']}
          gridColumnGap={5}
          css={css({ width: '100%', position: 'relative', px: 'pageMargin' })}
        >
          <RichText
            {...text}
            justifySelf={['center', null, 'start']}
            alignItems={['center', null, 'start']}
            textAlign={['center', null, 'start']}
            css={css({ py: 'pageMargin', maxWidth: '32rem' })}
          />
          <Box
            css={css({
              position: 'relative',
              justifyContent: 'center',
              my: hasFullImage ? 4 : 0,
            })}
          >
            {media && (
              <Img
                {...(hasFullImage ? media[1] : media[0])}
                objectFit="contain"
                css={css({
                  maxHeight: `calc(100% + var(--section-margin))`,
                  position: [
                    'relative !important',
                    null,
                    'absolute !important',
                  ],
                  bottom: 0,
                  left: 0,
                  height: hasFullImage ? '100%' : 'auto',
                  width: ['100%', '60%', '100%'],
                })}
              />
            )}
          </Box>
        </Grid>
      </Box>
    </ModuleWrapper>
  );
};
