import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { minWidth } from 'src/utils/breakpoints';
import { clamp, mix, progress } from '@popmotion/popcorn';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import { useStore } from 'src/components/GlobalState';

const Wrapper = styled.div`
  perspective: 4000px;
  width: 100%;
  height: 100%;

  @media ${minWidth.m} {
    transform: translateX(5%);
  }
`;

const BaseWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: translate(50%, 50%);
`;

const Base = styled.div`
  position: absolute;
  transform-style: preserve-3d;
`;

const Scrubber = styled.div`
  position: absolute;
  top: 564px;
  left: 40px;
`;

const Annotations = styled.div`
  position: absolute;
  top: 125px;
  left: 200px;
`;

const TabletAnimation = () => {
  const { base, scrubber, annotations } = useStaticQuery(graphql`
    query {
      base: contentfulAsset(
        title: { eq: "device-animation-module-tablet-base" }
      ) {
        fixed(width: 1194, height: 913) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      scrubber: contentfulAsset(
        title: { eq: "device-animation-module-tablet-scrubber" }
      ) {
        fixed(width: 734, height: 82) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      annotations: contentfulAsset(
        title: { eq: "device-animation-module-tablet-annotations" }
      ) {
        fixed(width: 436, height: 276) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
    }
  `);

  // cache viewport
  const reflow = useStore((state) => state.reflow);

  // cache wrapper node and bounds
  const [nodeWrapper, boundsWrapper] = useBounds();

  // cache child nodes
  const nodeBase = useRef();
  const nodeScrubber = useRef();
  const nodeAnnotations = useRef();

  // set image width
  const imageWidth = 1194;

  // in frame
  useAnimationFrame(() => {
    // determine vertical progress of Base
    const progressBase = clamp(
      0,
      1,
      progress(-reflow.height, reflow.height, boundsWrapper.current.y)
    );

    // determine vertical progress of child nodes
    const progressChildren = clamp(
      0,
      1,
      progress(0, reflow.height, boundsWrapper.current.y)
    );

    // style Base
    nodeBase.current.style.transform = `
      translateX(-50%)
      translateY(-50%)
      scale(${(boundsWrapper.current.width / imageWidth) * 0.7})
      rotateX(${mix(0, 30, progressBase)}deg)
      rotateY(${mix(0, 20, progressBase)}deg)
      rotateZ(-5deg)
      translateZ(0px)
    `;

    // style Children
    // translateZ(0px) breaks z-indexing, so stop at 1
    const zScrubber = mix(1, 400, progressChildren);
    const zAnnotations = mix(1, 800, progressChildren);

    nodeScrubber.current.style.transform = `translateZ(${zScrubber}px)`;
    nodeAnnotations.current.style.transform = `translateZ(${zAnnotations}px)`;
  });

  return (
    <Wrapper ref={nodeWrapper}>
      <BaseWrapper>
        <Base ref={nodeBase}>
          <Img {...base} />
          <Scrubber ref={nodeScrubber}>
            <Img {...scrubber} />
          </Scrubber>
          <Annotations ref={nodeAnnotations}>
            <Img {...annotations} />
          </Annotations>
        </Base>
      </BaseWrapper>
    </Wrapper>
  );
};

export default TabletAnimation;
