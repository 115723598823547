import React from 'react';
import css from 'src/theme/css';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import ModuleWrapper from 'src/components/ModuleWrapper';
import TextBlock from 'src/components/TextBlock';
import Link from 'src/components/Link';
import { Grid } from 'src/components/FlexBox';
import Layers from 'src/components/Layers';
import ImageGroup from 'src/components/ImageGroup';
import SmartButton from 'src/components/SmartButton';

export default ({ text, media, background }) => {
  const textBllockWithoutButtons = text.json.content.filter(
    (block) => block.nodeType !== 'embedded-entry-block'
  );
  const textWithoutButtons = {
    json: {
      content: textBllockWithoutButtons,
    },
  };
  const buttonsBllockWithoutText = text.json.content.filter(
    (block) => block.nodeType === 'embedded-entry-block'
  );
  const buttonsWithoutText = {
    json: {
      content: buttonsBllockWithoutText,
    },
  };
  return (
    <ModuleWrapper css={css({ px: 'pageMargin', py: 4 })}>
      <Layers css={css({ borderRadius: 2, overflow: 'hidden' })}>
        <ImageGroup {...background} />
        <Grid
          gridTemplateColumns={['auto', null, '1fr 1fr']}
          gx={0}
          gy={5}
          css={css({
            px: 'pageMargin',
            py: 5,
            position: 'relative',
          })}
        >
          <div
            css={css({
              display: 'flex',
              flexDirection: 'column',
              my: 'auto',
              mr: [0, null, 3, 5],
            })}
          >
            <TextBlock
              css={css({
                maxWidth: ['100%', null, '480px'],
              })}
              {...textWithoutButtons}
            />
            <TextBlock
              {...buttonsWithoutText}
              horizontal={true}
              css={css({
                mt: 4,
                '> * + *': {
                  width: ['100%', 'auto'],
                  mt: [4, 0],
                  ml: [0, 4],
                },
                display: 'flex',
                flexDirection: 'row',
                flexWrap: ['wrap', 'no-wrap'],
                justifyContent: ['center', null, 'flex-start'],
                alignItems: 'center',
              })}
            />
          </div>
          <Link url={'/whats-new'}>
            <Img
              {...media[0]}
              css={css({ borderRadius: 2, overflow: 'hidden' })}
            />
          </Link>
        </Grid>
      </Layers>
    </ModuleWrapper>
  );
};
