import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import CustomersHeroModule from 'src/components/CustomersHeroModule';
import CountdownModule from 'src/components/CountdownModule';
import AppCtaModule from 'src/components/AppCtaModule';
import SpeedComparisonModule from 'src/components/SpeedComparisonModule';
import DeviceAnimationModule from 'src/components/DeviceAnimationModule';
import C2CCTAModule from 'src/components/C2CCTAModule';
import WhatsNewCustomModule from 'src/components/WhatsNewCustomModule';
import C2CIntegrationsModule from 'src/components/C2CIntegrationsModule';

const componentLookup = {
  'Customers Hero': CustomersHeroModule,
  Countdown: CountdownModule,
  'App CTA': AppCtaModule,
  'Speed Comparison': SpeedComparisonModule,
  'Device Animation': DeviceAnimationModule,
  'C2C CTA': C2CCTAModule,
  'Whats New CTA': WhatsNewCustomModule,
  'C2C Integrations': C2CIntegrationsModule,
};

export default ({ type, ...other }) => {
  const Component = get(componentLookup, type, () => <></>);
  return <Component {...other} />;
};

export const query = graphql`
  fragment CustomModuleFragment on ContentfulCustomModule {
    id
    type
    text {
      json
    }
    media {
      fluid(maxWidth: 2000, quality: 90) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    background {
      ... on ContentfulImageGroup {
        ...ImageGroupFragment
      }
    }
    references {
      ... on ContentfulArticle {
        slug
        videoUrl
        brand {
          identifier
          media {
            file {
              url
            }
          }
        }
        previewImage {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      ... on ContentfulImageGroup {
        ...ImageGroupFragment
      }
      ... on ContentfulTextBlock {
        ...TextBlockFragment
      }
    }
    other {
      internal {
        content
      }
    }
  }
`;
