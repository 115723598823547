// DEPRECATED

import { css } from 'styled-components';

import { gradientBG } from 'src/utils/mixins';

import THEMES from 'src/constants/themes';

const blackTheme = ({ theme }) => css`
  background-color: ${theme.color.black};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  label,
  b,
  strong {
    color: ${theme.color.white};
  }

  & p,
  & ul li {
    color: ${theme.color.gray200};
  }
`;

const darkPurpleTheme = ({ theme }) => css`
  ${blackTheme}

  background-color: ${theme.color.purpleDark};
`;

const darkGrayTheme = ({ theme }) => css`
  ${blackTheme}

  background-color: ${theme.color.gray900};
`;

const whiteTheme = ({ theme }) => css`
  background-color: ${theme.color.white};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  label,
  b,
  strong {
    color: ${theme.color.black};
  }

  & p,
  & ul li {
    color: ${theme.color.gray600};
  }
`;

const lightGrayTheme = ({ theme }) => css`
  ${whiteTheme}

  background-color: ${theme.color.gray100};
`;

const gradientTheme = ({ theme }) => css`
  ${gradientBG()}

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & p,
  & ul li {
    color: ${theme.color.white};
  }
`;

const globalThemeStyles = {
  [THEMES.BLACK]: blackTheme,
  [THEMES.DARK_PURPLE]: darkPurpleTheme,
  [THEMES.DARK_GRAY]: darkGrayTheme,
  [THEMES.WHITE]: whiteTheme,
  [THEMES.LIGHT_GRAY]: lightGrayTheme,
  [THEMES.GRADIENT]: gradientTheme,
};

export default globalThemeStyles;
