import cond from 'lodash/cond';

export default function switchcase(pairs, switchValue, switchDefault) {
  const condPairs = pairs.map(([key, value]) => [
    () => key === switchValue,
    value,
  ]);
  condPairs.push([() => true, switchDefault]);
  return cond(condPairs)(switchValue);
}
