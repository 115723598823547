import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useCountdown from 'src/hooks/useCountdown';
import css from 'src/theme/css';
import ModuleWrapper from 'src/components/ModuleWrapper';
import SmartButton from 'src/components/SmartButton';
import Text from 'src/components/Text';
import { Flex, Box } from 'src/components/FlexBox';
import { useBreakpoint } from 'src/components/Breakpoints';

const Number = ({ number, label }) => {
  return (
    <Flex flexDirection="column" textAlign="center" alignItems="center">
      <Text
        css={css({
          fontSize: 'inherit',
          fontFamily: 'aktiv',
          fontWeight: 'regular',
          letterSpacing: '-0.06ch',
          lineHeight: 1,
          color: 'white',
          position: 'relative',
          width: '2ch',
          height: '1em',
          transform: 'translateZ(0)',
        })}
      >
        {number}
      </Text>
      <Text variant="small" color="white">
        {label}
      </Text>
    </Flex>
  );
};

export default ({ other }) => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: contentfulAsset(title: { eq: "cloud-countdown-bg" }) {
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);

  const { date } = JSON.parse(other.internal.content);
  const items = useCountdown(date);
  const hasCountdown = useBreakpoint(1);

  return (
    <ModuleWrapper
      background={{
        objectPosition: 'top',
        color: 'teaser-2',
        largeImage: backgroundImage,
        smallImage: backgroundImage,
      }}
      isCard={true}
    >
      <Flex
        gy={5}
        gx={4}
        flexDirection={['column', null, 'row-reverse']}
        justifyContent="space-between"
        alignItems="center"
        css={css({
          px: 'pageMargin',
          py: ['2rem', '3rem', null, null, '4rem'],
        })}
      >
        {hasCountdown && (
          <Flex
            gx="0.8ch"
            css={css({
              fontSize: ['14vw', '9vw', '6vw', 8],
            })}
          >
            {Object.entries(items).map(([label, number]) => (
              <Number number={number} label={label} key={label} />
            ))}
          </Flex>
        )}
        <Box
          flexDirection="column"
          alignItems={['center', null, 'flex-start']}
          textAlign={['center', null, 'start']}
          flexGrow={1}
        >
          <Text
            css={css({
              fontSize: 14 / 16 + 'rem',
              fontWeight: 'demibold',
            })}
          >
            2/11/2021, 11:00 AM PST
          </Text>
          <Text
            variant="heading.m"
            css={css({
              fontFamily: 'aktiv',
              fontWeight: 'medium',
              mt: 2,
              mb: 3,
            })}
          >
            Lights. Camera. Cloud. You're invited to the launch of something
            big.
          </Text>
          <SmartButton
            action="Video Modal"
            variant="Knockout"
            videoUrl="https://youtu.be/u8AmHBhEQAk"
            label="Watch the live event"
          ></SmartButton>
        </Box>
      </Flex>
    </ModuleWrapper>
  );
};
