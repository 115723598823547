import { useState, useEffect } from 'react';
import sync, { cancelSync } from 'framesync';
import parseMilliseconds from 'parse-ms';

function pad(n, digits = 2) {
  const s = Math.max(n, 0).toString();
  const l = Math.max(digits - s.length + 1, 0);
  return new Array(l).join('0').concat(s);
}

export default (date) => {
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  useEffect(() => {
    const process = sync.update(() => {
      const parsedDate = new Date(date);
      const deltaTime = parsedDate.getTime() - Date.now();

      const parsed = parseMilliseconds(Math.max(deltaTime), 0);

      if (parsed.days !== days) {
        setDays(pad(parsed.days));
      }
      if (parsed.hours !== hours) {
        setHours(pad(parsed.hours));
      }
      if (parsed.minutes !== minutes) {
        setMinutes(pad(parsed.minutes));
      }
      if (parsed.seconds !== seconds) {
        setSeconds(pad(parsed.seconds));
      }
    }, true);

    return () => cancelSync.read(process);
  }, [
    setDays,
    setHours,
    setMinutes,
    setSeconds,
    days,
    hours,
    minutes,
    seconds,
    date,
  ]);
  return { days, hours, minutes, seconds };
};
