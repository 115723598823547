export default {
  DARK: 'Dark',
  LIGHT: 'Light',
  LIGHT_GRAY: 'Light gray',
  DARK_PURPLE: 'Dark purple',
  DARK_GRAY: 'Dark gray',
  WHITE: 'White',
  BLACK: 'Black',
  GRADIENT: 'Gradient',
};
