import React, { useEffect, useRef } from 'react';
import BackgroundImage from 'gatsby-background-image';
import CircleIcon from 'src/components/CircleIcon';
import Icon from 'src/components/IconPreset';
import styled from 'styled-components';
import { media } from 'src/utils/mixins';
import ChevronRightIcon from 'src/assets/svg/ChevronRight';

const Hero = styled.div`
  overflow: hidden;

  position: relative;

  height: 100vh;
  margin-top: calc(var(--header-height) * -1);
  padding-top: var(--header-height);

  background-color: #000;
`;

const Enter = styled.div`
  position: absolute;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;
  user-select: none;

  &.is-hidden {
    opacity: 0;
    visibility: hidden;

    transition-property: opacity, visibility;
    transition-duration: 1s, 0s;
    transition-timing-function: ease, linear;
    transition-delay: 0s, 1s;
  }
`;

const EnterPiece = styled.div`
  opacity: 0;

  display: flex;
  align-items: center;

  height: 35vh;

  &:first-child {
    margin-bottom: 50px;
  }

  &.is-shown {
    opacity: 1;

    transform: translate3d(0, 0, 0);
    transition-property: opacity, transform;
    transition-duration: 1s, 1s;
    transition-timing-function: ease;
  }
`;

const EnterPieceTop = styled(EnterPiece)`
  transform: translate3d(-100px, 0, 0);
`;

const EnterPieceBottom = styled(EnterPiece)`
  transform: translate3d(100px, 0, 0);
`;

const EnterImage = styled(BackgroundImage)`
  overflow: hidden;

  width: 47.5vw;
  height: 35vh;

  border-radius: 22.5px;

  &.is-hidden {
    visibility: hidden;
  }
`;

const EnterImageRight = styled(EnterImage)`
  &.is-hidden {
    visibility: visible;

    transform: translate3d(100px, 0, 0);
    transition: transform 1s ease;
  }
`;

const EnterImageLeft = styled(EnterImage)`
  &.is-hidden {
    visibility: visible;

    transform: translate3d(-100px, 0, 0);
    transition: transform 1s ease;
  }
`;

const EnterLogo = styled.div`
  width: 35vh;
  height: 35vh;
  margin-right: 50px;
  margin-left: 50px;

  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #14151d;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Caro = styled.div`
  visibility: hidden;

  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  height: 100%;

  &.is-shown {
    visibility: visible;
  }

  ${media.medium`
    position: absolute;
    top: var(--header-height);
    right: 0;
    bottom: 0;
    left: 0;

    flex-direction: row;

    height: auto;
  `}
`;

const CaroHeadings = styled.div`
  z-index: 3;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 88vw;
  margin: 0 6vw 50px 6vw;

  pointer-events: none;
  user-select: none;

  ${media.medium`
    position: absolute;
    top: 48.85%;
    left: 9.875%;

    align-items: flex-start;

    width: auto;
    margin: 0;
  `}
`;

const CaroHeading = styled.h1`
  opacity: 0;

  transform: translate3d(0, 15px, 0);
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease;

  max-width: 205px;
  margin-bottom: 20px;

  font-family: 'aktiv-grotesk', sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 1;
  letter-spacing: -2.5px;
  text-align: center;
  text-indent: -2.5px;
  color: #fff;

  &.is-active {
    opacity: 1;

    transform: translate3d(0, 0, 0);
  }

  ${media.medium`
    max-width: none;

    font-size: 88px;
    text-align: left;
  `}
`;

const CaroSubhead = styled.h2`
  opacity: 0;

  transform: translate3d(0, 15px, 0);
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0.25s;

  max-width: 230px;

  font-family: 'Avenir Next', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.125px;
  text-align: center;
  color: #b8c1cf;

  &.is-active {
    opacity: 1;

    transform: translate3d(0, 0, 0);
  }

  ${media.medium`
    max-width: 505px;

    font-size: 20px;
    line-height: 1.35;
    text-align: left;
  `}
`;

const CaroArrowLeft = styled(CircleIcon)`
  transform: rotate(180deg);

  position: absolute;
  top: 50%;
  left: 0;

  margin-top: -14px;

  ${media.medium`
    display: none;
  `}
`;

const CaroArrowRight = styled(CircleIcon)`
  position: absolute;
  top: 50%;
  right: 0;

  margin-top: -14px;

  ${media.medium`
    display: none;
  `}
`;

const CaroItems = styled.div`
  position: relative;

  width: 88vw;
  height: 88vw;

  ${media.medium`
    position: absolute;
    top: 50%;
    left: 50%;

    width: 70vmin;
    height: 70vmin;
    margin-top: -35vmin;
    margin-left: -35vmin;
  `}
`;

const CaroItem = styled(BackgroundImage)`
  overflow: hidden;

  opacity: 1;

  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 20px;

  &.is-mid {
    z-index: 1;

    transform: scale3d(1, 1, 1);

    cursor: pointer;
  }

  &.is-left {
    opacity: 0.5 !important;

    transform: translate3d(calc(-59.5vmin - 25px), 0, 0) scale3d(0.7, 0.7, 1);
  }

  &.is-right {
    opacity: 0.5 !important;

    transform: translate3d(calc(59.5vmin + 25px), 0, 0) scale3d(0.7, 0.7, 1);
  }

  &.prep-left {
    opacity: 0;

    transform: scale3d(0.7, 0.7, 1);
  }

  &.prep-right {
    opacity: 0;

    transform: scale3d(0.7, 0.7, 1);
  }
`;

const CaroItemIcon = styled(CircleIcon)`
  opacity: 0;

  transition: opacity 0.5s ease;

  position: absolute;
  bottom: 25px;
  right: 25px;

  .is-mid & {
    opacity: 1;
  }

  .is-mid:hover & {
    svg {
      transform: scale(1.1);
    }

    .circle {
      stroke: var(--hover-stroke);
      fill: var(--hover-fill);
    }

    .icon {
      fill: var(--hover-color);
      stroke: var(--hover-color);
    }
  }

  ${media.medium`
    bottom: 35px;
    right: 35px;
  `}
`;

const CaroItemLogo = styled(Icon)`
  position: absolute;
  top: 25px;
  left: 25px;

  pointer-events: none;
  user-select: none;

  ${media.medium`
    top: 35px;
    left: 35px;
  `}
`;

const CaroDots = styled.div`
  display: none;

  ${media.medium`
    transform: translate3d(-50%, 50%, 0);

    position: absolute;
    bottom: calc((100% - 70vmin) / 4);
    left: 50%;

    display: flex;
    align-items: center;
  `}
`;

const CaroDotsArrowRight = styled.div`
  opacity: 0.3;

  transition: opacity 0.25s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 10px;
  height: 15px;

  cursor: pointer;
  fill: #fff;

  &:hover {
    opacity: 1;
  }
`;

const CaroDotsArrowLeft = styled.div`
  opacity: 0.3;

  transform: rotate(180deg);
  transition: opacity 0.25s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 10px;
  height: 15px;
  margin-right: 42px;

  cursor: pointer;
  fill: #fff;

  &:hover {
    opacity: 1;
  }
`;

const DecoyWrap = styled.div`
  visibility: hidden;

  position: absolute;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;
  user-select: none;

  &.is-shown {
    visibility: visible;
  }
`;

const Decoy = styled(BackgroundImage)`
  overflow: hidden;

  will-change: width, height, transform, visibility;

  transition-property: width, height, transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  position: absolute;
  top: 50%;
  left: 50%;

  width: 88vw;
  height: 88vw;
  margin-top: -44vw;
  margin-left: -44vw;

  border-radius: 22.5px;

  ${media.medium`
    width: 70vmin;
    height: 70vmin;
    margin-top: -35vmin;
    margin-left: -35vmin;
  `}
`;

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const end = (...nodes) => {
  const promise = new Promise((resolve) => {
    let current = 0;

    const expected = nodes.length;

    const handleTransitionEnd = (node) => (event) => {
      // remove listener
      node.removeEventListener('transitionend', handleTransitionEnd);

      // increment current
      current += 1;

      // check if done
      if (current === expected) {
        resolve();
      }
    };

    nodes.forEach((node) =>
      node.addEventListener('transitionend', handleTransitionEnd(node))
    );
  });

  return promise;
};

const gradientBackground = (fluid) => [
  'linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 1) 100%)',
  fluid,
];

export default ({ references, text }) => {
  const hero = useRef();

  const enter = useRef();
  const enterDecoy = useRef();
  const enterPieceTop = useRef();
  const enterPieceBottom = useRef();
  const enterImageLeft = useRef();
  const enterImageRight = useRef();

  const caro = useRef();
  const caroHeading = useRef();
  const caroItemLeft = useRef();
  const caroItemMid = useRef();
  const caroItemRight = useRef();
  const caroSubhead = useRef();

  const caroDotsArrowLeft = useRef();
  const caroDotsArrowRight = useRef();

  const decoy = useRef();
  const decoyWrap = useRef();

  useEffect(() => {
    const mounted = async () => {
      // STEP 1
      // setup Decoy to mimic EnterImage
      const decoyBounds = decoy.current.selfRef.getBoundingClientRect();
      const mimicBounds = enterDecoy.current.selfRef.getBoundingClientRect();

      const translateX = (decoyBounds.width - mimicBounds.width) / 2;
      const translateY = mimicBounds.top - decoyBounds.top;

      decoy.current.selfRef.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`;
      decoy.current.selfRef.style.width = `${mimicBounds.width}px`;
      decoy.current.selfRef.style.height = `${mimicBounds.height}px`;

      await wait(0);

      // STEP 2
      // move EnterPieces into position
      window.requestAnimationFrame(() => {
        enterPieceBottom.current.classList.add('is-shown');
        enterPieceTop.current.classList.add('is-shown');
      });

      await end(enterPieceBottom.current, enterPieceTop.current);

      // STEP 3
      // show the Decoy, hide the EnterImage
      decoyWrap.current.classList.add('is-shown');
      enterDecoy.current.selfRef.classList.add('is-hidden');

      await wait(0);

      window.requestAnimationFrame(() => {
        // animate out Entrance and EnterImages
        enter.current.classList.add('is-hidden');
        enterImageLeft.current.selfRef.classList.add('is-hidden');
        enterImageRight.current.selfRef.classList.add('is-hidden');

        // animate Decoy
        decoy.current.selfRef.style.transform = 'none';
        decoy.current.selfRef.style.removeProperty('width');
        decoy.current.selfRef.style.removeProperty('height');
      });

      await end(decoy.current.selfRef);

      // hide Decoy
      decoyWrap.current.classList.remove('is-shown');

      // show Caro
      caroItemMid.current.selfRef.classList.add('is-mid');
      caro.current.classList.add('is-shown');

      await end(
        enter.current,
        enterImageLeft.current.selfRef,
        enterImageRight.current.selfRef
      );

      // STEP 4
      // show CaroItems
      window.requestAnimationFrame(() => {
        caroItemLeft.current.selfRef.classList.remove('prep-left');
        caroItemRight.current.selfRef.classList.remove('prep-right');

        caroItemLeft.current.selfRef.classList.add('is-left');
        caroItemRight.current.selfRef.classList.add('is-right');
      });

      await end(caroItemLeft.current.selfRef, caroItemRight.current.selfRef);

      // STEP 5
      // show headings
      window.requestAnimationFrame(() => {
        caroHeading.current.classList.add('is-active');
        caroSubhead.current.classList.add('is-active');
      });

      await end(caroHeading.current, caroSubhead.current);

      // STEP 6
      // setup Caro
      let current = [0, 1, 2];

      const nodes = [
        caroItemLeft.current.selfRef,
        caroItemMid.current.selfRef,
        caroItemRight.current.selfRef,
      ];

      const update = () => {
        window.requestAnimationFrame(() => {
          // cleanup old classes
          nodes.forEach((node) =>
            node.classList.remove('is-left', 'is-mid', 'is-right')
          );

          // add new classes
          nodes[current[0]].classList.add('is-left');
          nodes[current[1]].classList.add('is-mid');
          nodes[current[2]].classList.add('is-right');
        });
      };

      const moveRight = () => {
        // update indices
        const updated = [current[2], current[0], current[1]];

        // replace indices
        current = updated;

        // prep z-indexes
        nodes[current[0]].style.zIndex = '0';
        nodes[current[1]].style.zIndex = '2';
        nodes[current[2]].style.zIndex = '1';

        // move nodes
        update();
      };

      const moveLeft = () => {
        // update indices
        const updated = [current[1], current[2], current[0]];

        // replace indices
        current = updated;

        // prep z-indexes
        nodes[current[2]].style.zIndex = '0';
        nodes[current[1]].style.zIndex = '2';
        nodes[current[0]].style.zIndex = '1';

        // move nodes
        update();
      };

      const handleCardClick = (index) => (event) => {
        event.stopPropagation();

        if (index === current[1]) {
          const { host, protocol } = window.location;
          const pathname = nodes[current[1]].getAttribute('href');
          const url = `${protocol}//${host}/${pathname}`;
          window.location = url;
          return;
        }

        if (index === current[0]) {
          moveRight();
          return;
        }

        if (index === current[2]) {
          moveLeft();
          return;
        }
      };

      nodes.forEach((node, index) =>
        node.addEventListener('click', handleCardClick(index))
      );

      hero.current.addEventListener('click', ({ clientX }) => {
        // not mobile? exit early
        if (window.innerWidth > 960) return;

        // otherwise, move based on click location
        if (clientX > window.innerWidth / 2) {
          moveRight();
        } else {
          moveLeft();
        }
      });

      caroDotsArrowLeft.current.addEventListener('click', moveLeft);
      caroDotsArrowRight.current.addEventListener('click', moveRight);
    };

    mounted();
  }, []);

  return (
    <Hero ref={hero}>
      <Enter ref={enter}>
        <EnterPieceTop ref={enterPieceTop}>
          <EnterImageLeft
            fluid={references[0].previewImage.fluid}
            ref={enterImageLeft}
          />

          <EnterLogo
            style={{
              backgroundImage: `url(${references[0].brand.media.file.url})`,
            }}
          />

          <EnterImageRight
            fluid={references[1].previewImage.fluid}
            ref={enterImageRight}
          />
        </EnterPieceTop>

        <EnterPieceBottom ref={enterPieceBottom}>
          <EnterLogo
            style={{
              backgroundImage: `url(${references[1].brand.media.file.url})`,
            }}
          />

          <EnterImage
            fluid={gradientBackground(references[2].previewImage.fluid)}
            ref={enterDecoy}
          />

          <EnterLogo
            style={{
              backgroundImage: `url(${references[2].brand.media.file.url})`,
            }}
          />
        </EnterPieceBottom>
      </Enter>

      <Caro ref={caro}>
        <CaroItems>
          <CaroItem
            fluid={gradientBackground(references[0].previewImage.fluid)}
            href={references[0].slug}
            ref={caroItemLeft}
            style={{ position: 'absolute' }}
          >
            <CaroItemLogo {...references[0].brand} />
            <CaroItemIcon type={references[0].videoUrl ? 'play' : 'text'} />
          </CaroItem>

          <CaroItem
            fluid={gradientBackground(references[2].previewImage.fluid)}
            href={references[2].slug}
            ref={caroItemMid}
            style={{ position: 'absolute' }}
          >
            <CaroItemLogo {...references[2].brand} />
            <CaroItemIcon type={references[2].videoUrl ? 'play' : 'text'} />
          </CaroItem>

          <CaroItem
            fluid={gradientBackground(references[1].previewImage.fluid)}
            href={references[1].slug}
            ref={caroItemRight}
            style={{ position: 'absolute' }}
          >
            <CaroItemLogo {...references[1].brand} />
            <CaroItemIcon type={references[1].videoUrl ? 'play' : 'text'} />
          </CaroItem>
        </CaroItems>

        <CaroHeadings>
          <CaroHeading ref={caroHeading}>
            {text.json.content[0].content[0].value}
          </CaroHeading>

          <CaroSubhead ref={caroSubhead}>
            {text.json.content[1].content[0].value}
          </CaroSubhead>

          <CaroArrowLeft isSmall={true} type="arrow" />
          <CaroArrowRight isSmall={true} type="arrow" />
        </CaroHeadings>

        <CaroDots>
          <CaroDotsArrowLeft ref={caroDotsArrowLeft}>
            <ChevronRightIcon />
          </CaroDotsArrowLeft>

          <CaroDotsArrowRight ref={caroDotsArrowRight}>
            <ChevronRightIcon />
          </CaroDotsArrowRight>
        </CaroDots>
      </Caro>

      <DecoyWrap ref={decoyWrap}>
        <Decoy
          fluid={gradientBackground(references[2].previewImage.fluid)}
          ref={decoy}
          style={{ position: 'absolute' }}
        />
      </DecoyWrap>
    </Hero>
  );
};
